import { i18next } from '@translate/i18n';
import { Region } from '@entities/region';

export const formatStatus = status => {
  return status === 'ACTIVE'
    ? 'success'
    : status === 'PENDING' || status === 'REJECTED'
    ? 'warning'
    : status === 'BANNED' || status === 'INACTIVE'
    ? 'danger'
    : 'primary';
};

export const enumStatus = [
  { text: 'Ativo', value: 'ACTIVE' },
  { text: 'Pendente', value: 'PENDING' },
  { text: 'Banido', value: 'BANNED' },
  { text: 'Inativo', value: 'INACTIVE' },
  { text: 'Rejeitado', value: 'REJECTED' },
];

export const signupFlowStatus = [
  { text: 'PENDING_DOCS', value: 'PENDING_DOCS' },
  { text: 'LEAD', value: 'LEAD' },
  { text: 'WAITING_LIST', value: 'WAITING_LIST' },
];

export const work_cities = Region.getWorkCities();

export const enumLicenseStatus = [
  { value: null, enable: false, text: i18next.t('drivers.dropdown.licenseStatus.NULL_AND_CHECKING') },
  { value: 'ACTIVE', enable: true, text: i18next.t('drivers.dropdown.licenseStatus.ACTIVE') },
  { value: 'EXPIRED', enable: true, text: i18next.t('drivers.dropdown.licenseStatus.EXPIRED') },
  { value: 'SUSPENDED', enable: true, text: i18next.t('drivers.dropdown.licenseStatus.SUSPENDED') },
  { value: 'INVALID', enable: false, text: i18next.t('drivers.dropdown.licenseStatus.INVALID') },
  { value: 'SGD_FAILED_AND_INVALID', enable: false, text: i18next.t('drivers.dropdown.licenseStatus.SGD_FAILED_AND_INVALID') },
  { value: 'CANCELED', enable: false, text: i18next.t('drivers.dropdown.licenseStatus.CANCELED') },
  { value: 'INACTIVE', enable: false, text: i18next.t('drivers.dropdown.licenseStatus.INACTIVE') },
];

export const leadStatus = ['LEAD', 'PROSPECT', 'LEAD_CHURN', 'WAITING_LIST'];
