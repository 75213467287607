import map from 'lodash/map';
import { i18next } from '@translate/i18n';

export const formatFuel = (fuel, spacer = ', ') => {
  return map(fuel, 'name').join(spacer);
};

export const formatStatus = status => {
  return status === 'ACTIVE' || status === 'RESERVED'
    ? 'success'
    : status === 'RETIRED_RETURN' || status === 'OPS' || status === 'PRE_OPERATION'
    ? 'secondary'
    : status === 'MAINTENANCE_FIXES' || status === 'MAINTENANCE_ISSUE' || status === 'MAINTENANCE_RECURRENT'
    ? 'warning'
    : status === 'RETIRED_THEFT' || status === 'RETIRED_CRASH'
    ? 'danger'
    : 'primary';
};

export const enumOBDProvider = [{ text: 'SafeCar', value: 'SAFECAR' }];

export const enumFleetFlags = [
  { text: i18next.t('fleetProfile.tags.KOVI_MAX'), value: 'KOVI_MAX' },
  { text: i18next.t('fleetProfile.tags.REPLACE_VEHICLE'), value: 'REPLACE_VEHICLE', needAcl: true },
  { text: i18next.t('fleetProfile.tags.RENT_TO_OWN'), value: 'RENT_TO_OWN' },
  { text: i18next.t('fleetProfile.tags.PENDING_INSPECTION'), value: 'PENDING_INSPECTION', needAcl: true },
  { text: i18next.t('fleetProfile.tags.GAS_KIT_MOBILIZATION'), value: 'GAS_KIT_MOBILIZATION' },
  { text: i18next.t('fleetProfile.tags.FOR_SALE'), value: 'FOR_SALE' },
  { text: i18next.t('fleetProfile.tags.MAINTENANCE_DRIVER'), value: 'MAINTENANCE_DRIVER' },
  { text: i18next.t('fleetProfile.tags.DEMOBILIZATION'), value: 'DEMOBILIZATION', needAcl: true },
  { text: i18next.t('fleetProfile.tags.PENDING_DIAGNOSIS'), value: 'PENDING_DIAGNOSIS' },
  { text: i18next.t('fleetProfile.tags.LEGAL_BLOCK'), value: 'LEGAL_BLOCK', needAcl: true },
  { text: i18next.t('fleetProfile.tags.FLEET_READY_FOR_SALE'), value: 'FLEET_READY_FOR_SALE' },
  { text: i18next.t('fleetProfile.tags.PENDING_TRIAGE'), value: 'PENDING_TRIAGE' },
  { text: i18next.t('fleetProfile.tags.MECHANICAL_MAINTENANCE'), value: 'MECHANICAL_MAINTENANCE' },
  { text: i18next.t('fleetProfile.tags.BODYSHOP_MAINTENANCE'), value: 'BODYSHOP_MAINTENANCE' },
  { text: i18next.t('fleetProfile.tags.KEY_SERVICE'), value: 'KEY_SERVICE' },
  { text: i18next.t('fleetProfile.tags.TRACKING_MAINTENANCE'), value: 'TRACKING_MAINTENANCE' },
  { text: i18next.t('fleetProfile.tags.TAPESTRY_MAINTENANCE'), value: 'TAPESTRY_MAINTENANCE' },
  { text: i18next.t('fleetProfile.tags.GLASS_MAINTENANCE'), value: 'GLASS_MAINTENANCE' },
  { text: i18next.t('fleetProfile.tags.BODYSHOP_EXPRESS'), value: 'BODYSHOP_EXPRESS' },
  { text: i18next.t('fleetProfile.tags.TOTAL_LOSS'), value: 'TOTAL_LOSS', needAcl: true },
  { text: i18next.t('fleetProfile.tags.THEFT_ROBBERY'), value: 'THEFT_ROBBERY', needAcl: true },
  { text: i18next.t('fleetProfile.tags.THEFT_ROBBERY_ANALYSIS'), value: 'THEFT_ROBBERY_ANALYSIS', needAcl: true },
  { text: i18next.t('fleetProfile.tags.CONTRACT_ENDING'), value: 'CONTRACT_ENDING', needAcl: true },
  { text: i18next.t('fleetProfile.tags.SUITABLE_FOR_KP2'), value: 'SUITABLE_FOR_KP2', needAcl: true },
  { text: i18next.t('fleetProfile.tags.PENDING_RECALL'), value: 'PENDING_RECALL', needAcl: true },
  { text: i18next.t('fleetProfile.tags.RECOVERED_FROM_FLOOD'), value: 'RECOVERED_FROM_FLOOD', needAcl: true },
  { text: i18next.t('fleetProfile.tags.ACCIDENT_WITH_DRIVER'), value: 'ACCIDENT_WITH_DRIVER', needAcl: true },
  { text: i18next.t('fleetProfile.tags.QUALITY_CELL'), value: 'QUALITY_CELL', needAcl: true },
  { text: i18next.t('fleetProfile.tags.SOLD_TO_B2C'), value: 'SOLD_TO_B2C', needAcl: true },
  { text: i18next.t('fleetProfile.tags.CAR_SUPPLY_VALIDATION'), value: 'CAR_SUPPLY_VALIDATION', needAcl: true },
];

export const enumStatus = [
  { text: i18next.t('fleetProfile.status.PRE_OPERATION'), value: 'PRE_OPERATION' },
  { text: i18next.t('fleetProfile.status.ACTIVE'), value: 'ACTIVE', needAcl: true },
  { text: i18next.t('fleetProfile.status.MAINTENANCE_RECURRENT'), value: 'MAINTENANCE_RECURRENT', needAcl: true },
  { text: i18next.t('fleetProfile.status.MAINTENANCE_FIXES'), value: 'MAINTENANCE_FIXES', needAcl: true },
  { text: i18next.t('fleetProfile.status.MAINTENANCE_ISSUE'), value: 'MAINTENANCE_ISSUE', needAcl: true },
  { text: i18next.t('fleetProfile.status.RETIRED_THEFT'), value: 'RETIRED_THEFT' },
  { text: i18next.t('fleetProfile.status.RETIRED_CRASH'), value: 'RETIRED_CRASH' },
  { text: i18next.t('fleetProfile.status.RETIRED_RETURN'), value: 'RETIRED_RETURN' },
  { text: i18next.t('fleetProfile.status.RETIRED_SOLD_B2C'), value: 'RETIRED_SOLD_B2C', needAcl: true },
  { text: i18next.t('fleetProfile.status.OPS'), value: 'OPS', needAcl: true },
  { text: i18next.t('fleetProfile.status.RESERVED'), value: 'RESERVED' },
  { text: i18next.t('fleetProfile.status.PREPARATION'), value: 'PREPARATION' },
  { text: i18next.t('fleetProfile.status.INSURANCE'), value: 'INSURANCE' },
  { text: i18next.t('fleetProfile.status.SEIZED'), value: 'SEIZED', needAcl: true, cannotEdit: true },
  { text: i18next.t('fleetProfile.status.INSPECTION'), value: 'INSPECTION', needAcl: true },
];

export const enumStatusOperation = [
  {
    text: 'Desbloqueado',
    value: 'UNLOCKED',
  },
  {
    text: 'Bloqueio Pendente',
    value: 'LOCK_PENDING',
  },
  {
    text: 'Bloqueado',
    value: 'LOCKED',
  },
  {
    text: 'Desbloqueio Pendente',
    value: 'UNLOCK_PENDING',
  },
];

export const enumCarsModel = [
  {
    text: 'Cronos',
    value: 'CRONOS',
  },
  {
    text: 'Argo',
    value: 'ARGO',
  },
  {
    text: 'Polo Track',
    value: 'POLO_TRACK',
  },
  {
    text: 'Voyage',
    value: 'VOYAGE',
  },
  {
    text: 'HB20',
    value: 'HB20',
  },
  {
    text: 'Onix',
    value: 'ONIX',
  },
  {
    text: 'Logan',
    value: 'LOGAN',
  },
  {
    text: 'Kicks',
    value: 'KICKS',
  },
  {
    text: 'Mobi',
    value: 'MOBI',
  },
  {
    text: 'Kwid',
    value: 'KWID',
  },
  {
    text: 'Ford Ka',
    value: 'FORD_KA',
  },
  {
    text: 'Etios',
    value: 'ETIOS',
  },
  {
    text: 'Yaris',
    value: 'YARIS',
  },
  {
    text: 'Sandero',
    value: 'SANDERO',
  },
  {
    text: 'Gol',
    value: 'GOL',
  },
  {
    text: 'Reserva - Tanto faz o modelo',
    value: 'BACKUP',
  },
  {
    text: 'Outro ou não sei o modelo',
    value: 'OTHERS',
  },
];

export const enumCarsCategory = [
  {
    text: 'SUV Básico',
    value: 'SUV_BASICO',
  },
  {
    text: 'Sedan Premium',
    value: 'SEDAN_PREMIUM',
  },
  {
    text: 'Sedan Básico',
    value: 'SEDAN_BASICO',
  },
  {
    text: 'Hatch Básico',
    value: 'HATCH_BASICO',
  },
  {
    text: 'Hatch Econômico',
    value: 'HATCH_ECONOMICO',
  },
  {
    text: 'Hatch Comfort',
    value: 'HATCH_COMFORT',
  },
  {
    text: 'Outros',
    value: 'OTHERS',
    disabled: true,
  },
];

export const enumCarsProduct = [
  {
    text: 'Rental',
    value: 'RENTAL',
  },
  {
    text: 'RTO',
    value: 'RENT_TO_OWN',
  },
];
