import { Region } from '@entities/region';

export default {
  words: {
    remove: 'Remover',
    contact: 'Contato',
  },
  texts: {
    noResults: 'Nenhum resultado encontrado',
    search: 'Buscar por nome, CPF, telefone ou placa',
  },
  navbar: {
    support: 'Suporte',
    region: 'Região',
    zeroNotifications: 'Você não possui notificações',
  },
  region: {
    changeRegionButton: 'Mudar de região',
    changeRegionErrorTitle: 'Atenção, você não possui acesso a esta região!',
    changeRegionWarningTitle: 'Atenção, você está mudando de região!',
    regionFrom: 'Você está indo de:',
    regionTo: 'Para:',
    regionGroupEnum: Region.cityEnum,
  },
  access: {
    errorTitle: 'Você não possui esse acesso',
    errorMessage: 'Verifique com o responsável da sua área',
  },
};
